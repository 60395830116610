var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"asset-history",attrs:{"column":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.histories,"sort-by":['createdAt'],"sort-desc":[true],"loading":_vm.$apollo.loading},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('table',{staticClass:"date-action-by-table"},[_c('tbody',[_c('tr',[_c('td',{attrs:{"data-test":("assetHistory-table-row-" + (item.id) + "-cell-createdAt")}},[_vm._v(_vm._s(_vm.formatDisplayDateTime(item.createdAt)))])]),_c('tr',[_c('td',{attrs:{"data-test":("assetHistory-table-row-" + (item.id) + "-cell-event")}},[_vm._v(_vm._s(item.changeType))])]),_c('tr',[_c('td',{attrs:{"data-test":("assetHistory-table-row-" + (item.id) + "-cell-tokenSubject")}},[_vm._v(_vm._s(_vm.formatTokenSubject(item.tokenSubject)))])])])])]}},{key:"item.fields",fn:function(ref){
var item = ref.item;
return [_c('v-simple-table',{staticClass:"fields-table"},[_c('tbody',_vm._l((item.changes),function(change,i){return _c('tr',{key:i},[_c('td',{attrs:{"data-test":("assetHistory-table-row-" + (item.id) + "-cell-fields-" + (change.key))}},[_vm._v(_vm._s(change.field))])])}),0)])]}},{key:"item.old",fn:function(ref){
var item = ref.item;
return [_c('v-simple-table',{staticClass:"fields-table"},[_c('tbody',_vm._l((item.changes),function(change,i){return _c('tr',{key:i},[(change.old === null)?_c('td',{staticClass:"caption",attrs:{"data-test":("assetHistory-table-row-" + (item.id) + "-cell-fields-" + (change.key) + "-old-value")}},[_c('i',[_vm._v("null")])]):_c('td',{attrs:{"data-test":("assetHistory-table-row-" + (item.id) + "-cell-fields-" + (change.key) + "-old-value")}},[(change.oldItem && change.oldItem.path)?_c('router-link',{attrs:{"to":{ name: change.oldItem.path, params: { id: change.old, type: change.oldItem.type } }}},[_vm._v(_vm._s(change.oldItem.name))]):_c('span',[_vm._v(_vm._s(!!change.oldItem && change.oldItem.name || change.old))])],1)])}),0)])]}},{key:"item.new",fn:function(ref){
var item = ref.item;
return [_c('v-simple-table',{staticClass:"fields-table"},[_c('tbody',_vm._l((item.changes),function(change,i){return _c('tr',{key:i},[(change.new === null)?_c('td',{staticClass:"caption",attrs:{"data-test":("assetHistory-table-row-" + (item.id) + "-cell-fields-" + (change.key) + "-new-value")}},[_c('i',[_vm._v("null")])]):_c('td',{attrs:{"data-test":("assetHistory-table-row-" + (item.id) + "-cell-fields-" + (change.key) + "-new-value")}},[(change.newItem && change.newItem.path)?_c('router-link',{attrs:{"to":{ name: change.newItem.path, params: { id: change.new, type: change.newItem.type } }}},[_vm._v(_vm._s(change.newItem.name))]):_c('span',[_vm._v(_vm._s(!!change.newItem && change.newItem.name || change.new))])],1)])}),0)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }