import FullScreenLoader from '@/components/FullScreenLoader/FullScreenLoader.vue'
import AssetExtraFields from '@/components/Asset/AssetExtraFields/AssetExtraFields.vue'
import AssetDiscovery from '@/components/Asset/AssetDiscovery/AssetDiscovery.vue'
import AssetHistory from '@/components/Asset/AssetHistory/AssetHistory.vue'
import FormattingMixin from '@/mixins/formatting.mixin'
import ExportMixin from '@/mixins/export.mixin'
import AssetStatusChip from '@/components/Asset/AssetStatusChip/AssetStatusChip.vue'
import Memo from '@/components/inputs/Memo/Memo.vue'
import PhoneNumber from 'awesome-phonenumber'
import { mapMutations } from 'vuex'
import DeleteAsset from '@/graphql/DeleteAsset.gql'
import RestoreAsset from '@/graphql/RestoreAsset.gql'
import CloneAsset from '@/graphql/CloneAsset.gql'
import assetTypes from '@/store/AssetTypes/index.js'
import AssetMixin from '@/mixins/asset.mixin'

const requiredRule = (v) => !!v || 'Required'
const preventWhiteSpaceRule = (v) => (v && !/\s/.test(v)) || 'White space is not allowed.'

export default {
  props: { id: { type: String, default: null }, assetGraphType: { type: String, default: null } },
  mixins: [ExportMixin, FormattingMixin, AssetMixin],
  components: { FullScreenLoader, AssetStatusChip, AssetHistory, AssetExtraFields, AssetDiscovery, Memo },
  data() {
    return {
      asset: {},
      submitting: false,
      confirmDeleteAssetDialog: false,
      confirmRestoreAssetDialog: false,
      confirmCloneAssetDialog: false,
      newSerialNumber: null,
      newDocomoSerialNumber: null,
      valid: false,
      errorMessages: { name: [], serialNumber: [], model: [] },
      hasActiveDuplicate: false
    }
  },
  watch: {
    newDocomoSerialNumber: {
      handler(value) {
        const iccid = this.convertDocomoSerialNumberToIccid(value)
        if (iccid !== null) {
          this.newSerialNumber = iccid
          this.validateSerialNumber()
        }
      }
    },
    'asset.model': {
      handler(model) {
        if (model) {
          this.asset.manufacturerId = model.manufacturer.id
          // trigger serial number validation
          if (this.newSerialNumber) {
            this.$nextTick(this.$refs.serialNumber.focus)
            this.$nextTick(this.$refs.serialNumber.blur)
          }
          // update the manufacturer
          this.manufacturer = model.manufacturer.name
        }
      }
    }
  },
  computed: {
    serialNumberRules() {
      if (this.asset.__typename === 'Sim') {
        return [
          requiredRule,
          preventWhiteSpaceRule,
          (v) => !!this.asset.model || 'Select Model first',
          (v) => (v && /^\d+$/.test(v)) || 'ICCID should only contains digits, the last character is a check digit and should be ignored.',
          (v) => (v && v.length <= 19) || 'ICCID is maximum 19 characters long'
        ]
      } else {
        return [
          requiredRule,
          preventWhiteSpaceRule,
          (v) => !!this.asset.model || 'Select Model first',
          (v) => (v && v.length <= 64) || 'Exceeds maximum length'
        ]
      }
    },
    docomoSerialNumberRules() {
      // allowed first 2 characters
      const set1 = ['DN', 'GE', 'AX', 'TS', 'GD']

      return [
        (v) => !v || v.length === 15 || 'Docomo serial number should be 15 characters long',
        (v) => !v || set1.includes(v.substring(0, 2)) || `The first 2 characters must be one of ${set1.toString()}`,
        (v) => !v || /^[0-6]$/.test(v.substring(5, 6)) || 'The 6th character must be a digit between 0 - 6',
        (v) => !v || /^[0-9]+$/.test(v.substring(6, 14)) || 'The 7th to 14th characters should only contain number'
      ]
    },
    cards() {
      const assetData = (key) => _.get(this.asset, key, '') || ''
      let nameChildren = []
      if (this.currentType.graphTypeName === 'Sim') {
        nameChildren = [
          {
            title: 'Local phone number',
            value: _.get(new PhoneNumber(assetData('name')), 'a.number.national', ''),
            hasPermission:
              this.ability.can('read', this.currentType.graphTypeName, 'name') || this.ability.can('read', this.currentType.graphTypeName, 'Name')
          }
        ]
      } else {
        nameChildren = null
      }
      let cards = [
        // Location card
        {
          title: this.getFieldLabel('location'),
          hasPermission:
            this.ability.can('read', this.currentType.graphTypeName, 'region') ||
            this.ability.can('read', this.currentType.graphTypeName, 'Region') ||
            this.ability.can('read', this.currentType.graphTypeName, 'office') ||
            this.ability.can('read', this.currentType.graphTypeName, 'Office'),
          items: [
            {
              title: 'Region',
              value: assetData('region'),
              hasPermission:
                this.ability.can('read', this.currentType.graphTypeName, 'region') ||
                this.ability.can('read', this.currentType.graphTypeName, 'Region')
            },
            {
              title: 'Country',
              value: this.formatCountry(this.asset['countryIsoCode2']),
              hasPermission:
                this.ability.can('read', this.currentType.graphTypeName, 'countryIsoCode2') ||
                this.ability.can('read', this.currentType.graphTypeName, 'CountryIsoCode2')
            },
            {
              title: 'Office',
              value: assetData('office.name'),
              hasPermission:
                this.ability.can('read', this.currentType.graphTypeName, 'office') ||
                this.ability.can('read', this.currentType.graphTypeName, 'Office'),
              children: [
                {
                  title: 'Address',
                  value: assetData('office.address'),
                  hasPermission: this.ability.can('read', 'Location', 'address') || this.ability.can('read', 'Location', 'Address')
                }
              ]
            }
          ]
        },
        // Basic Info card
        {
          title: 'Basic Info',
          hasPermission: true,
          items: [
            {
              title: this.getFieldLabel('serialNumber'),
              value: assetData('serialNumber'),
              hasPermission:
                this.ability.can('read', this.currentType.graphTypeName, 'serialNumber') ||
                this.ability.can('read', this.currentType.graphTypeName, 'SerialNumber')
            },
            {
              title: this.getFieldLabel('name'),
              value: assetData('name'),
              hasPermission:
                this.ability.can('read', this.currentType.graphTypeName, 'name') || this.ability.can('read', this.currentType.graphTypeName, 'Name'),
              children: nameChildren
            },
            {
              title: this.getFieldLabel('model'),
              value: assetData('model.name'),
              hasPermission:
                (this.ability.can('read', this.currentType.graphTypeName, 'model') ||
                  this.ability.can('read', this.currentType.graphTypeName, 'Model')) &&
                this.ability.can('read', 'Model'),
              children: [
                {
                  title: 'Model Number',
                  value: assetData('model.modelNumber'),
                  hasPermission: this.ability.can('read', 'Model', 'modelNumber') || this.ability.can('read', 'Model', 'ModelNumber')
                },
                {
                  title: this.getFieldLabel('manufacturer'),
                  value: assetData('model.manufacturer.name'),
                  hasPermission: this.ability.can('read', 'Manufacturer', 'name') || this.ability.can('read', 'Manufacturer', 'Name')
                },
                {
                  title: 'Asset Type',
                  value: assetData('model.assetType'),
                  hasPermission: this.ability.can('read', 'Model', 'assetType') || this.ability.can('read', 'Model', 'AssetType')
                }
              ]
            },
            {
              title: this.getFieldLabel('purchaseDate'),
              value: assetData('purchaseDate') ? this.formatDisplayDate(assetData('purchaseDate')) : '',
              hasPermission:
                this.ability.can('read', this.currentType.graphTypeName, 'purchaseDate') ||
                this.ability.can('read', this.currentType.graphTypeName, 'PurchaseDate')
            },
            {
              title: this.getFieldLabel('purchaseCost') || 'Purchase Cost',
              value: assetData('purchaseCost') ? `${assetData('purchaseCost')} ${assetData('purchaseCurrencyCode')}` : '',
              hasPermission:
                this.ability.can('read', this.currentType.graphTypeName, 'purchaseCost') ||
                this.ability.can('read', this.currentType.graphTypeName, 'PurchaseCost')
            },
            {
              title: this.getFieldLabel('costCode'),
              value: assetData('costCode'),
              hasPermission:
                this.ability.can('read', this.currentType.graphTypeName, 'costCode') ||
                this.ability.can('read', this.currentType.graphTypeName, 'CostCode')
            },
            {
              title: this.getFieldLabel('warrantyExpires'),
              value: assetData('warrantyExpires') ? this.formatDisplayDate(assetData('warrantyExpires')) : '',
              hasPermission:
                this.ability.can('read', this.currentType.graphTypeName, 'warrantyExpires') ||
                this.ability.can('read', this.currentType.graphTypeName, 'WarrantyExpires')
            }
          ]
        },
        // Status card
        {
          title: this.getFieldLabel('status'),
          hasPermission:
            this.ability.can('read', this.currentType.graphTypeName, 'status') ||
            this.ability.can('read', this.currentType.graphTypeName, 'Status') ||
            this.ability.can('read', this.currentType.graphTypeName, 'pclStatus') ||
            this.ability.can('read', this.currentType.graphTypeName, 'PclStatus') ||
            this.ability.can('read', this.currentType.graphTypeName, 'operationStatus') ||
            this.ability.can('read', this.currentType.graphTypeName, 'OperationStatus') ||
            this.ability.can('read', this.currentType.graphTypeName, 'isLegalHold'),
          items: [
            {
              title: 'Status',
              value: _.startCase(this.asset.status),
              hasPermission:
                this.ability.can('read', this.currentType.graphTypeName, 'status') ||
                this.ability.can('read', this.currentType.graphTypeName, 'Status')
            },
            {
              title: 'Pcl Status',
              value: _.startCase(this.asset['pclStatus']),
              hasPermission:
                this.ability.can('read', this.currentType.graphTypeName, 'pclStatus') ||
                this.ability.can('read', this.currentType.graphTypeName, 'PclStatus')
            },
            {
              title: 'Operation Status',
              value: _.startCase(this.asset['operationStatus']),
              hasPermission:
                this.ability.can('read', this.currentType.graphTypeName, 'operationStatus') ||
                this.ability.can('read', this.currentType.graphTypeName, 'OperationStatus')
            },
            {
              title: 'Legal Hold',
              value: _.startCase(this.asset['isLegalHold']),
              hasPermission:
                this.ability.can('read', this.currentType.graphTypeName, 'isLegalHold') ||
                this.ability.can('read', this.currentType.graphTypeName, 'IsLegalHold')
            }
          ]
        },
        // User/Owner Info card
        {
          title: 'User/Owner Info',
          hasPermission:
            this.ability.can('read', this.currentType.graphTypeName, 'assignee') ||
            this.ability.can('read', this.currentType.graphTypeName, 'Assignee'),
          items: [
            {
              title: 'Assignee',
              value: assetData('assignee.samAccountName') || assetData('assignee.name'),
              hasPermission:
                (this.ability.can('read', 'IntraAccount') || this.ability.can('read', 'Organization')) &&
                (this.ability.can('read', this.currentType.graphTypeName, 'assignee') ||
                  this.ability.can('read', this.currentType.graphTypeName, 'Assignee')),
              children: [
                {
                  title: 'Employee ID',
                  value: assetData('assignee.employeeId'),
                  hasPermission:
                    assetData('assignee.__typename') === 'IntraAccount' &&
                    assetData('assignee.employeeId') &&
                    (this.ability.can('read', 'IntraAccount', 'employeeId') || this.ability.can('read', 'IntraAccount', 'EmployeeId'))
                },
                {
                  title: 'Organization ID',
                  value: assetData('assignee.externalId'),
                  hasPermission:
                    assetData('assignee.__typename') === 'Organization' &&
                    assetData('assignee.externalId') &&
                    (this.ability.can('read', 'Organization', 'externalId') || this.ability.can('read', 'Organization', 'ExternalId'))
                },
                {
                  title: 'Name',
                  value: assetData('assignee.name'),
                  hasPermission:
                    this.ability.can('read', 'IntraAccount', 'name') ||
                    this.ability.can('read', 'IntraAccount', 'Name') ||
                    this.ability.can('read', 'Organization', 'name') ||
                    this.ability.can('read', 'Organization', 'Name')
                },
                {
                  title: 'Country',
                  value: this.formatCountry(assetData('assignee.countryIsoCode2')),
                  hasPermission:
                    (this.ability.can('read', 'IntraAccount', 'countryIsoCode2') || this.ability.can('read', 'IntraAccount', 'CountryIsoCode2')) &&
                    assetData('assignee.employeeId') &&
                    (this.ability.can('read', 'IntraAccount', 'employeeId') || this.ability.can('read', 'IntraAccount', 'EmployeeId'))
                },
                {
                  title: 'Location',
                  value: assetData('assignee.location.name'),
                  hasPermission:
                    (this.ability.can('read', 'IntraAccount', 'location') || this.ability.can('read', 'IntraAccount', 'Location')) &&
                    assetData('assignee.employeeId') &&
                    (this.ability.can('read', 'IntraAccount', 'employeeId') || this.ability.can('read', 'IntraAccount', 'EmployeeId'))
                }
              ]
            },
            {
              title: 'PrimaryUser',
              value: assetData('primaryUser.samAccountName') || assetData('primaryUser.name'),
              hasPermission:
                (this.ability.can('read', 'IntraAccount') || this.ability.can('read', 'Organization')) &&
                (this.ability.can('read', this.currentType.graphTypeName, 'primaryUser') ||
                  this.ability.can('read', this.currentType.graphTypeName, 'PrimaryUser')),
              children: [
                {
                  title: 'Employee ID',
                  value: assetData('primaryUser.employeeId'),
                  hasPermission:
                    assetData('primaryUser.__typename') === 'IntraAccount' &&
                    assetData('primaryUser.employeeId') &&
                    (this.ability.can('read', 'IntraAccount', 'employeeId') || this.ability.can('read', 'IntraAccount', 'EmployeeId'))
                },
                {
                  title: 'Organization ID',
                  value: assetData('primaryUser.externalId'),
                  hasPermission:
                    assetData('primaryUser.__typename') === 'Organization' &&
                    assetData('primaryUser.externalId') &&
                    (this.ability.can('read', 'Organization', 'externalId') || this.ability.can('read', 'Organization', 'ExternalId'))
                },
                {
                  title: 'Name',
                  value: assetData('primaryUser.name'),
                  hasPermission:
                    this.ability.can('read', 'IntraAccount', 'name') ||
                    this.ability.can('read', 'IntraAccount', 'Name') ||
                    this.ability.can('read', 'Organization', 'name') ||
                    this.ability.can('read', 'Organization', 'Name')
                }
              ]
            },
            {
              title: 'Company',
              value: assetData('company.name'),
              hasPermission: this.ability.can('read', 'Company', 'name') || this.ability.can('read', 'Company', 'Name'),
              children: [
                {
                  title: 'Company Id',
                  value: assetData('company.companyId'),
                  hasPermission: this.ability.can('read', 'Company', 'companyId') || this.ability.can('read', 'Company', 'CompanyId')
                },
                {
                  title: 'Local Name',
                  value: assetData('company.nameLocal'),
                  hasPermission: this.ability.can('read', 'Company', 'nameLocal') || this.ability.can('read', 'Company', 'NameLocal')
                },
                {
                  title: 'Deleted externally',
                  value: assetData('company.deletedExternally'),
                  hasPermission: this.ability.can('read', 'Company', 'deletedExternally') || this.ability.can('read', 'Company', 'DeletedExternally')
                }
              ]
            },
            {
              title: 'Last User',
              value: assetData('lastUser'),
              hasPermission:
                this.ability.can('read', this.currentType.graphTypeName, 'lastUser') ||
                this.ability.can('read', this.currentType.graphTypeName, 'LastUser')
            }
          ]
        },
        // Type-specific Info card
        { title: `${this.currentType.graphTypeName}-specific Info`, hasPermission: this.ability.can('read', this.currentType.graphTypeName) },
        // Discovery
        {
          title: `${this.currentType.graphTypeName}-specific Info`,
          hasPermission: this.ability.can('read', this.currentType.graphTypeName),
          items: [
            {
              title: 'Device Encryption Status',
              value: _.startCase(this.asset.deviceEncryptionStatus),
              hasPermission:
                this.ability.can('read', this.currentType.graphTypeName, 'deviceEncryptionStatus') ||
                this.ability.can('read', this.currentType.graphTypeName, 'DeviceEncryptionStatus')
            },
            {
              title: 'Password For Encryption',
              value: _.startCase(this.asset.passwordForEncryption),
              hasPermission:
                this.ability.can('read', this.currentType.graphTypeName, 'passwordForEncryption') ||
                this.ability.can('read', this.currentType.graphTypeName, 'PasswordForEncryption')
            },
            {
              title: 'Last Check In',
              value: this.formatDisplayDateTime(this.asset.lastCheckIn),
              hasPermission:
                this.ability.can('read', this.currentType.graphTypeName, 'lastCheckIn') ||
                this.ability.can('read', this.currentType.graphTypeName, 'LastCheckIn')
            },
            {
              title: 'Last Inventory Check',
              value: this.formatDisplayDateTime(this.asset.lastInventoryCheck),
              hasPermission:
                this.ability.can('read', this.currentType.graphTypeName, 'lastInventoryCheck') ||
                this.ability.can('read', this.currentType.graphTypeName, 'LastInventoryCheck')
            },
            {
              title: 'Mac Address',
              value: this.asset.macAddress,
              hasPermission:
                this.ability.can('read', this.currentType.graphTypeName, 'macAddress') ||
                this.ability.can('read', this.currentType.graphTypeName, 'MacAddress')
            },
            {
              title: 'Os Version',
              value: this.asset.osVersion,
              hasPermission:
                this.ability.can('read', this.currentType.graphTypeName, 'osVersion') ||
                this.ability.can('read', this.currentType.graphTypeName, 'OsVersion')
            },
            {
              title: 'Initial Enroll Date',
              value: this.formatDisplayDate(this.asset.initialEnrollDate),
              hasPermission:
                this.ability.can('read', this.currentType.graphTypeName, 'initialEnrollDate') ||
                this.ability.can('read', this.currentType.graphTypeName, 'InitialEnrollDate')
            },
            {
              title: 'Processor',
              value: this.asset.processor,
              hasPermission:
                this.ability.can('read', this.currentType.graphTypeName, 'processor') ||
                this.ability.can('read', this.currentType.graphTypeName, 'Processor')
            },
            {
              title: 'Memory (MB)',
              value: this.asset.memory,
              hasPermission:
                this.ability.can('read', this.currentType.graphTypeName, 'memory') ||
                this.ability.can('read', this.currentType.graphTypeName, 'Memory')
            },
            {
              title: 'Drive Capacity (MB)',
              value: this.asset.driveCapacity,
              hasPermission:
                this.ability.can('read', this.currentType.graphTypeName, 'driveCapacity') ||
                this.ability.can('read', this.currentType.graphTypeName, 'DriveCapacity')
            }
          ]
        }
      ]
      if (_.get(this.asset, 'usage') === 'SHARED') {
        cards
          .find((x) => x.title === 'Status')
          .items.push({
            title: 'Share Status',
            value: _.startCase(this.asset['shareStatus']),
            hasPermission:
              this.ability.can('read', this.currentType.graphTypeName, 'shareStatus') ||
              this.ability.can('read', this.currentType.graphTypeName, 'ShareStatus')
          })
      }
      return cards
    }
  },
  methods: {
    cloneAsset(asset) {
      let mutation = CloneAsset

      this.$apollo
        .mutate({ mutation, variables: { input: { assetId: asset.id, assetSerialNumber: this.newSerialNumber } } })
        .then(({ data }) => {
          this.setAppSnackbar({ text: `Cloned asset #${asset.id} ${asset.name} successfully.`, color: 'success' })
          this.submitting = false
          this.confirmCloneAssetDialog = false
          this.closeAssetDialog()
          this.$router.push({ name: 'list-assets', path: '/assets/allAssets', params: { updatedItem: asset } })
        })
        .catch((e) => {
          this.addError(null, `Failed to clone asset. ${e.toString()}`, 'form')
          this.submitting = false
          this.confirmCloneAssetDialog = false
        })
    },
    convertDocomoSerialNumberToIccid(value) {
      let iccid = '8981100'
      if (value) {
        switch (value.substring(0, 2)) {
          case 'DN':
            iccid += '00'
            break
          case 'AX':
            iccid += '02'
            break
          case 'GD':
            iccid += '05'
            break
          default:
            // do no update serial number
            return
        }

        let part2 = value.substring(5, 14)
        if (/^\d+$/.test(part2)) {
          iccid += part2
          return iccid
        }
      }
      return null
    },
    createAsset() {
      this.submitting = true

      let assetType = assetTypes.find((x) => x.graphTypeName === this.asset.__typename)
      this.asset.serialNumber = this.newSerialNumber

      if (assetType.name === 'sim') {
        this.asset.name = this.$refs.name.phoneObject.number.international
      }

      // prepare payload
      let input = {
        name: this.asset.name ? this.asset.name.trim() : null,
        status: this.asset.status ? this.asset.status.trim() : null,
        operationStatus: this.asset.operationStatus ? this.asset.operationStatus.trim() : null,
        serialNumber: this.asset.serialNumber ? this.asset.serialNumber.trim() : null,
        purchaseCost: this.asset.purchaseCost,
        purchaseCurrencyCode: this.asset.purchaseCurrencyCode ? this.asset.purchaseCurrencyCode.trim() : null,
        purchaseDate: this.asset.purchaseDate,
        warrantyExpires: this.asset.warrantyExpires,
        locationId: _.get(this.asset, 'location.id'),
        companyId: _.get(this.asset, 'company.id'),
        modelId: this.asset.model ? this.asset.model.id.trim() : null,
        assigneeId: this.asset.assigneeId ? this.asset.assigneeId : null,
        memo: this.asset.memo ? this.asset.memo.trim() : null,
        costCode: this.asset.costCode ? this.asset.costCode.trim() : null,
        cloneId: this.asset.id,
        region: this.asset.region,
        countryIsoCode2: this.asset.countryIsoCode2
      }

      // add extra fields
      const simSlotsInput = []
      switch (assetType.name) {
        case 'computer':
          this.asset.extraFields['simSlots'].forEach((x) => {
            const slot = { iMEI: x.iMEI, simId: _.get(x, 'sim.id') }

            // add id only if id exists
            if (!_.isEmpty(x.id)) {
              slot.id = x.id
            }

            // skip simslots without IMEI
            if (!_.isEmpty(slot.iMEI)) {
              simSlotsInput.push(slot)
            }
          })
          input['simSlots'] = simSlotsInput
          input['alternativeName'] = this.asset.extraFields['alternativeName'] || null
          input['pclStatus'] = this.asset.extraFields['pclStatus'] || null
          input['isLegalHold'] = this.asset.isLegalHold || null
          input['usage'] = this.asset.extraFields['usage'] || null
          if (input['usage'] === 'SHARED') {
            input['shareStatus'] = this.asset.extraFields['shareStatus'] || null
          } else {
            input['shareStatus'] = null
          }
          input['pcType'] = this.asset.extraFields['pcType'] || null
          input['pcSpecification'] = this.asset.extraFields['pcSpecification'] || null
          input['initialDeploymentDate'] = this.asset.extraFields['initialDeploymentDate']
          input['procurementRequestCode'] = this.asset.extraFields['procurementRequestCode']
          break
        case 'mobile':
          this.asset.extraFields['simSlots'].forEach((x) => {
            const slot = { iMEI: x.iMEI, simId: _.get(x, 'sim.id') }

            // add id only if id exists
            if (!_.isEmpty(x.id)) {
              slot.id = x.id
            }

            // skip simslots without IMEI
            if (!_.isEmpty(slot.iMEI)) {
              simSlotsInput.push(slot)
            }
          })
          input['simSlots'] = simSlotsInput
          input['initialDeploymentDate'] = this.asset.extraFields['initialDeploymentDate']
          break
        case 'sim':
          input['docomoSerialNumber'] = this.asset.docomoSerialNumber
          input['contractStartDate'] = this.asset.extraFields['contractStartDate']
          input['contractEndDate'] = this.asset.extraFields['contractEndDate']
          input['viberNumber'] = this.asset.extraFields['viberNumber']
          input['mailAddress'] = this.asset.extraFields['mailAddress']
          input['noticeMailAddress'] = this.asset.extraFields['noticeMailAddress']
          input['useType'] = this.asset.extraFields['useType'] || null
          input['rMobileSupportId'] = this.asset.extraFields['rMobileSupportId']
          input['rMobileSupportPasswordVersion'] = this.asset.extraFields['rMobileSupportPasswordVersion']
          break
        case 'securityDevice':
          input['initialDeploymentDate'] = this.asset.extraFields['initialDeploymentDate']
        default:
          // no extra fields
          break
      }

      this.$apollo
        .mutate({
          // Query
          mutation: this.$gql`
            mutation ${assetType.create.mutationName}($input: ${assetType.create.inputType}) {              ${assetType.create.mutationName}(input: $input) {                ${assetType.singleQueryName} {                  id
                  name
                }
              }
            }
          `,
          variables: { input }
        })
        .then(({ data }) => {
          const asset = _.get(data, `${assetType.create.mutationName}.${assetType.singleQueryName}`)
          if (asset && asset.id && asset.name) {
            this.cloneAsset(this.asset)
          } else {
            this.addError(null, 'Failed to clone asset.', 'form')
          }
        })
        .catch((e) => {
          this.addError(null, `Failed to clone asset. ${e.toString()}`, 'form')
          this.submitting = false
        })
    },
    deleteAsset(asset) {
      let mutation = DeleteAsset
      this.submitting = true
      this.$apollo
        .mutate({ mutation, variables: { input: { assetId: asset.id } } })
        .then(({ data }) => {
          this.setAppSnackbar({ text: `Deleted asset #${asset.id} ${asset.name} successfully.`, color: 'success' })
          this.submitting = false
          this.confirmDeleteAssetDialog = false
          this.closeAssetDialog()
          this.$router.push({ path: '/trash/allAssets', params: { updatedItem: asset } })
        })
        .catch((e) => {
          this.addError(null, `Failed to delete asset. ${e.toString()}`, 'form')
          this.submitting = false
          this.confirmDeleteAssetDialog = false
        })
    },
    restoreAsset(asset) {
      let mutation = RestoreAsset
      this.submitting = true
      this.$apollo
        .mutate({ mutation, variables: { input: { assetId: asset.id } } })
        .then(({ data }) => {
          this.setAppSnackbar({ text: `Restored asset #${asset.id} ${asset.name} successfully.`, color: 'success' })
          this.submitting = false
          this.closeAssetDialog()
          this.$router.push({ name: 'list-assets', path: '/assets/allAssets', params: { updatedItem: asset } })
        })
        .catch((e) => {
          this.addError(null, `Failed to restore asset. ${e.toString()}`, 'form')
          this.submitting = false
        })
    },
    closeAssetDialog() {
      this.$emit('closeAssetDialog', null)
    },
    tagClicked(tag) {
      this.$emit('tagClicked', tag)
    },
    updateAsset(asset) {
      this.$emit('updateAsset', asset)
    },
    async validateField(value, path, additionalRules = []) {
      let assetType = assetTypes.find((x) => x.graphTypeName === this.asset.__typename)
      this.whereConditions = [{ path, comparison: 'equal', value }]
      if (this.id) {
        this.whereConditions.push({ path: 'id', comparison: 'equal', value: this.id, negate: true })
      }
      // check other conditions if they have value
      for (let rule of additionalRules) {
        if (rule.value) {
          this.whereConditions.push(rule)
        } else {
          // reset conditions when at least one of the conditions does not have value
          this.whereConditions = []
          let rulePath = null
          if (rule.path === 'model.manufacturer.id') {
            if (assetType.singleQueryName === 'sim') {
              rulePath = 'Carrier'
            } else {
              rulePath = 'Manufacturer'
            }
          }
          this.errorMessages[path].push(rulePath + ' not selected')
        }
      }

      if (!_.isEmpty(this.whereConditions)) {
        const { data, error } = await this.$apollo.query({
          // Query
          query: this.$gql`
                  query assets($where: [WhereExpression]) {                    ${assetType.listQueryName}(where: $where) {                      totalCount
                    }
                  }
                `,
          variables: { where: this.whereConditions }
        })

        if (data && data[assetType.listQueryName]) {
          const assets = data[assetType.listQueryName]
          const x = _.find(assetType.fields, (x) => x.name === this.whereConditions[0].path).displayName || this.whereConditions[0].path
          const msg = `Asset with the same ${x} exists`
          if (assets.totalCount > 0) {
            this.errorMessages[this.whereConditions[0].path].push(msg)
          } else {
            this.errorMessages[this.whereConditions[0].path] = _.remove(this.errorMessages[this.whereConditions[0].path], msg)
          }
        } else if (error) {
          this.graphQLOnError(`Failed to validate asset data from server. ${error.toString()}`)
          this.assets = {}
        } else {
          this.graphQLOnError('Failed to connect to server.')
          this.assets = {}
        }
      }
    },
    async validateSerialNumber() {
      if (!_.isEmpty(this.newSerialNumber)) {
        await this.validateField(this.newSerialNumber, 'serialNumber', [
          { path: 'model.manufacturer.id', comparison: 'equal', value: this.asset.manufacturerId }
        ])
      }
    },
    async checkForActiveDuplicate(asset) {
      let whereConditions = []

      whereConditions.push({ path: 'isDeleted', comparison: 'equal', value: false, negate: false })

      if (asset.serialNumber === '' || asset.serialNumber === null || asset.serialNumber === undefined) {
        whereConditions.push({ path: 'name', comparison: 'equal', value: asset.name, negate: false })
      } else {
        whereConditions.push({ path: 'serialNumber', comparison: 'equal', value: asset.serialNumber, negate: false })
      }

      const { data, error } = await this.$apollo.query({
        // Query
        query: this.$gql`
          query assets($where: [WhereExpression]) {            ${this.currentType.listQueryName}(where: $where) {              totalCount
            }
          }
        `,
        variables: { where: whereConditions }
      })

      if (data && data[this.currentType.listQueryName]) {
        const assets = data[this.currentType.listQueryName]
        if (assets.totalCount > 0) {
          this.hasActiveDuplicate = true
        } else {
          this.hasActiveDuplicate = false
        }
      }

      if (error) {
        this.hasActiveDuplicate = false
      }
    },
    ...mapMutations(['setAppSnackbar'])
  },
  apollo: {
    asset: {
      query() {
        return this.getAssetQuery()
      },
      variables() {
        return { id: parseInt(this.id) }
      },
      skip() {
        return !this.id
      },
      update(data) {
        this.updateAsset(data[this.currentType.singleQueryName])
        this.checkForActiveDuplicate(data[this.currentType.singleQueryName])
        return data[this.currentType.singleQueryName]
      },
      result({ error }) {
        if (error) {
          this.graphQLOnError(`Failed to get asset data from server. ${error.toString()}`)
          if (error.toString().includes('Not found')) {
            this.renderError(404)
          }
        }
      }
    }
  },
  created() {
    if (_.isNaN(parseInt(this.id)) || parseInt(this.id) > 2147483647) {
      this.renderError(404)
    }
  }
}
