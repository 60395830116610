import AssetMixin from '@/mixins/asset.mixin'

export default {
  props: {
    asset: { type: Object, default: () => {} },
    assetGraphType: { type: String, default: null }
  },
  mixins: [AssetMixin],
  data() {
    return {
      showPassword: false,
      actualPassword: null,
      loadingPassword: false,
      fields: []
    }
  },
  watch: {
    asset() {
      this.fields = []
      this.setFields()
    }
  },
  apollo: {
    getRMobileSupport: {
      query() {
        return this.$gql`
          query RMobileSupport(
            $rMobileSupportId: String!,
            $simId: ID
          ) {
            rMobileSupport(rMobileSupportId: $rMobileSupportId, simId: $simId) {
              rMobileSupportPassword
            }
          }
        `
      },
      variables() {
        return { rMobileSupportId: this.asset['rMobileSupportId'], simId: this.asset.id }
      },
      skip() {
        return !this.showPassword
      },
      update: () => null,
      result({ data, error }) {
        this.actualPassword = data.rMobileSupport.rMobileSupportPassword
        this.loadingPassword = false
      }
    }
  },
  methods: {
    togglePassword() {
      this.loadingPassword = true
      this.showPassword = !this.showPassword
      if (!this.showPassword) {
        this.actualPassword = null
        this.loadingPassword = false
      }
    },
    setFields() {
      for (let extraField of this.extraOrReadOnlyFields.filter(f => f.name !== 'jamfComputer'
        && f.name !== 'crowdstrikeComputer'
        && f.name !== 'intuneComputer'
        && f.name !== 'pclStatus'
        && f.name !== 'shareStatus'
        && f.name !== 'isLegalHold'
        && !f.discovery)) {
          var fieldNameProperCase = extraField.name.length > 1 ? extraField.name.charAt(0).toUpperCase() + extraField.name.slice(1) : extraField.name
          this.fields.push({
            value: this.asset[extraField.name],
            hasPermission: this.ability.can('read', this.currentType.graphTypeName, extraField.name) || this.ability.can('read', this.currentType.graphTypeName, fieldNameProperCase),
            ...extraField
        })
      }
    },
    getFieldDisplayName(f) {
      return f.displayName || _.startCase(f.name)
    }
  },
  created() {
    this.setFields()
  }
}
