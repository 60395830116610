import AssetMixin from '@/mixins/asset.mixin'
import GetAssetHistories from '@/graphql/GetAssetHistories.gql'

export default {
  props: ['asset', 'type', 'rams-asset'],
  mixins: [AssetMixin],
  data() {
    return {
      lastUpdate: null
    }
  },
  apollo: {
    histories: {
      query: GetAssetHistories,
      variables() {
        return {
          id: parseInt(this.ramsAsset.id)
        }
      },
      update: ({ asset }) => asset.histories,
      result({ data, error }) {
        if (error) {
          this.graphQLOnError(`Failed to get histories. ${error.toString()}`)
        } else {
          this.lastUpdate = _.last(_.map(_.filter(data.asset.histories, { changeType: 'UPDATE' }), 'createdAt'))
        }
      },
      skip() {
        return !this.ramsAsset.id
      }
    }
  }
}
