<template>
  <v-chip class="ml-1" :color="_.get(status, 'color')" :disabled="isDisabled" dark>
    {{ _.get(status, 'text') }}
  </v-chip>
</template>
<script>
import AssetMixin from '@/mixins/asset.mixin'

export default {
  props: ['value', 'isDisabled'],
  mixins: [AssetMixin],
  computed: {
    status() {
      return this.statusMap[this.value]
    }
  }
}
</script>
