import { render, staticRenderFns } from "./AssetHistory.html?vue&type=template&id=5d6be02c&"
import script from "./AssetHistory.js?vue&type=script&lang=js&"
export * from "./AssetHistory.js?vue&type=script&lang=js&"
import style0 from "./AssetHistory.scss?vue&type=style&index=0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VDataTable,VLayout,VSimpleTable})
